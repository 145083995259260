import React from 'react';
import { ACCESS } from 'shared/constants/AppEnums.js';
const { ADMIN, STAFF, VIEWER } = ACCESS;

export const custodianPagesConfig = [
  {
    auth: [ADMIN, STAFF, VIEWER],
    routes: [
      {
        path: '/custodians',
        exact: true,
        component: React.lazy(() => import('./CustodiansPage')),
      },
      {
        path: '/custodians/:custodianId/:subSection',
        exact: true,
        component: React.lazy(() => import('./CustodianPage')),
      },
      {
        path: '/custodians/add',
        exact: true,
        component: React.lazy(() => import('./CustodianInfoPage')),
      },
    ],
  },
];
