import React from 'react';
import { ACCESS } from 'shared/constants/AppEnums.js';
const { ADMIN, STAFF, VIEWER } = ACCESS;

export const homestayPagesConfig = [
  {
    auth: [ADMIN, STAFF, VIEWER],
    routes: [
      {
        path: '/homestays',
        exact: true,
        component: React.lazy(() => import('./HomestaysPage')),
      },
      {
        path: '/homestays/:homestayId',
        exact: true,
        component: React.lazy(() => import('./HomestayPage')),
      },
      {
        path: '/homestays/add',
        exact: true,
        component: React.lazy(() => import('./HomestayPage')),
      },
    ],
  },
];
