import { isStaff, isAdmin, isViewer } from 'shared/utils/utils';

// All google material design icon list: https://material.io/resources/icons/?style=baseline

const routesConfig = (user) => {
  const access = user.access;
  const userChildrenRoutes = [];
  const menu = [];

  // users
  if (isAdmin(access)) {
    userChildrenRoutes.push({
      id: 'staff',
      title: 'Staffs',
      messageId: 'staffs.title',
      type: 'item',
      icon: 'person',
      url: '/staffs',
    });
    userChildrenRoutes.push({
      id: 'viewers',
      title: 'Viewers',
      messageId: 'viewers.title',
      type: 'item',
      icon: 'people',
      url: '/viewers',
    });
    menu.push({
      id: 'users',
      title: 'Users',
      messageId: 'common.users',
      type: 'collapse',
      icon: 'people',
      children: userChildrenRoutes,
    });
  }

  menu.push({
    id: 'hosts',
    title: 'Hosts',
    messageId: 'hosts.title',
    type: 'item',
    icon: 'home',
    url: '/hosts',
  });
  menu.push({
    id: 'student',
    title: 'Student',
    messageId: 'students.title',
    type: 'item',
    icon: 'face',
    url: '/students',
  });
  menu.push({
    id: 'custodians',
    title: 'Custodians',
    messageId: 'custodians.title',
    type: 'item',
    icon: 'verified_user',
    url: '/custodians',
  });
  menu.push({
    id: 'homestays',
    title: 'Homestays',
    messageId: 'homestays.title',
    type: 'item',
    icon: 'hotel',
    url: '/homestays',
  });

  if (isStaff(access) || isAdmin(access)) {
    menu.push({
      id: 'homestayRequests',
      title: 'Homestay Applications',
      messageId: 'homestayRequests.title',
      type: 'item',
      icon: 'pending_actions',
      url: '/homestayRequests',
    });
    menu.push({
      id: 'hostRequests',
      title: 'Host Applications',
      messageId: 'hostRequests.title',
      type: 'item',
      icon: 'assignment_ind',
      url: '/hostRequests',
    });

    menu.push({
      id: 'metadata',
      title: 'MetaData',
      messageId: 'metadata.title',
      type: 'item',
      icon: 'build',
      url: '/metadata',
    });
  }

  return menu;
};
export default routesConfig;
