const { isString, toString } = require('lodash');

export const isAdmin = (access) => access === 0;
export const isStaff = (access) => access === 1;
export const isViewer = (access) => access === 2;

export const getMsgFromError = (error) => {
  if (error == null) {
    return error;
  }
  if (error.response) {
    return `${error.response.status}: ${JSON.stringify(
      error.response.data,
      null,
      2,
    )}`;
  } else if (error.message) {
    return error.message;
  } else if (error.request) {
    return isString(error.request) ? error.request : toString(error.request);
  } else if (isString(error)) {
    return error;
  } else {
    return toString(error);
  }
};
