export default [
  // {
  //   id: 201,
  //   image: 'https://via.placeholder.com/150',
  //   message: 'Hey welcome!',
  //   name: 'Admin',
  // },
  // {
  //   id: 203,
  //   image: 'https://via.placeholder.com/150',
  //   message: 'Call me when you are free!',
  //   name: 'Application reviewer',
  // },
];
