import { createSelector } from 'reselect';
import _ from 'lodash';

export const selectMetaData = (state) => state.metaData;

// export const selectDisciplines = createSelector(selectMetaData, (metaData) => {
//   return metaData && metaData.disciplines;
// });

// export const selectDisciplinesMap = createSelector(
//   selectDisciplines,
//   (disciplines) => {
//     return _.keyBy(disciplines, 'id');
//   },
// );

// export const selectFileTypes = createSelector(selectMetaData, (metaData) => {
//   return metaData && metaData.file_types;
// });

// export const selectFileTypesMap = createSelector(
//   selectFileTypes,
//   (fileTypes) => {
//     return _.keyBy(fileTypes, 'id');
//   },
// );

export const selectSchools = createSelector(selectMetaData, (metaData) => {
  return metaData && metaData.schools;
});

export const selectHasSchools = createSelector(
  selectSchools,
  (schools) => Boolean(schools) && schools.length > 0,
);

export const selectFileTypes = createSelector(selectMetaData, (metaData) => {
  return (metaData && metaData.file_types) || [];
});
