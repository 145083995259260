import React from 'react';
import { ACCESS } from 'shared/constants/AppEnums.js';
const { ADMIN, STAFF } = ACCESS;

export const homestayRequestPagesConfig = [
  {
    routes: [
      {
        auth: [ADMIN, STAFF],
        path: '/homestayRequests',
        exact: true,
        component: React.lazy(() => import('./HomestayRequestsPage')),
      },
      {
        noSideBar: true,
        path: '/homestayRequests/form/:homestayRequestId',
        exact: true,
        component: React.lazy(() => import('./HomestayRequestFormPage')),
      },
      {
        auth: [ADMIN, STAFF],
        path: '/homestayRequests/add',
        exact: true,
        component: React.lazy(() => import('./HomestayRequestPage')),
      },
      {
        auth: [ADMIN, STAFF],
        path: '/homestayRequests/edit/:homestayRequestId',
        exact: true,
        component: React.lazy(() => import('./HomestayRequestPage')),
      },
    ],
  },
];
