import React from 'react';
import { Redirect } from 'react-router-dom';
import { createRoutes } from '../@crema/utility/Utils';
import { errorPagesConfigs } from './errorPages';
import { authRouteConfig } from './auth';
import { initialUrl } from '../shared/constants/AppConst';
import { staffPagesConfig } from './staffs/routesConfig';
import { studentsPagesConfig } from './students/routesConfig';
import { metadataPagesConfig } from './metadata/routesConfig';
import { userPagesConfig } from './users/routesConfig';
import { resetPasswordPagesConfig } from './resetPassword/routesConfig';
import { hostPagesConfig } from './hosts/routesConfig';
import { custodianPagesConfig } from './custodians/routesConfig';
import { homestayPagesConfig } from './homestays/routesConfig';
import { hostRequestPagesConfig } from './hostRequests/routesConfig';
import { homestayRequestPagesConfig } from './homestayRequests/routesConfig';

// reminder: add page config here
const routeConfigs = [
  ...errorPagesConfigs,
  ...authRouteConfig,
  ...staffPagesConfig,
  ...studentsPagesConfig,
  ...metadataPagesConfig,
  ...userPagesConfig,
  ...resetPasswordPagesConfig,
  ...hostPagesConfig,
  ...custodianPagesConfig,
  ...homestayPagesConfig,
  ...hostRequestPagesConfig,
  ...homestayRequestPagesConfig,
];

const routes = [
  ...createRoutes(routeConfigs),
  {
    path: '/',
    exact: true,
    component: () => <Redirect to={initialUrl} />,
  },
  {
    component: () => <Redirect to='/error-pages/error-404' />,
  },
];

export default routes;
