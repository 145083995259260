import React from 'react';
import { ACCESS } from 'shared/constants/AppEnums.js';
const { ADMIN, STAFF } = ACCESS;

export const hostRequestPagesConfig = [
  {
    routes: [
      {
        auth: [ADMIN, STAFF],
        path: '/hostRequests',
        exact: true,
        component: React.lazy(() => import('./HostRequestsPage')),
      },
      {
        noSideBar: true,
        path: '/hostRequests/form/:hostRequestId',
        exact: true,
        component: React.lazy(() => import('./HostRequestFormPage')),
      },
      {
        auth: [ADMIN, STAFF],
        path: '/hostRequests/add',
        exact: true,
        component: React.lazy(() => import('./HostRequestPage')),
      },
      {
        auth: [ADMIN, STAFF],
        path: '/hostRequests/edit/:hostRequestId',
        exact: true,
        component: React.lazy(() => import('./HostRequestPage')),
      },
    ],
  },
];
