import jwtAxios from '@crema/services/auth';

export const getMetadata = () => {
  return jwtAxios.get('/metadata').then((resp) => {
    return resp.data;
  });
};

export const addSchool = ({ school_name, school_address }) => {
  return jwtAxios.post('/metadata/schools', {
    school_name,
    school_address,
  });
};

export const updateSchool = ({ id, school_name, school_address }) => {
  return jwtAxios.patch(`/metadata/schools/${id}`, {
    school_name,
    school_address,
  });
};

// export const addFileType = ({ file_type }) => {
//   return jwtAxios.post('/metadata/file_types/add', {
//     file_type,
//   });
// };

export const updateFileType = ({ id, template_name, template_link }) => {
  return jwtAxios.patch(`/metadata/file_types/${id}`, {
    template_name,
    template_link,
  });
};
