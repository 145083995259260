import axios from 'axios';

const devBaseURL = 'http://localhost:8888/';
export const prodBaseURL = 'https://trust-homestay.herokuapp.com';

let baseURL = devBaseURL;
if (process.env.REACT_APP_API_URL) {
  baseURL = process.env.REACT_APP_API_URL;
}

if (process.env.NODE_ENV === 'production') {
  baseURL = prodBaseURL;
}

const jwtAxios = axios.create({
  baseURL,
  headers: {
    'Content-Type': 'application/json',
  },
});
export default jwtAxios;
