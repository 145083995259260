import { createSlice } from '@reduxjs/toolkit';

const initialState = [];

const hostsSlice = createSlice({
  name: 'hosts',
  initialState,
  reducers: {
    setHosts(state, action) {
      return action.payload;
    },
  },
});

export const { setHosts } = hostsSlice.actions;

export default hostsSlice.reducer;
