import enLang from './entries/en-US';
import zhLang from './entries/zh-Hans-CN';
import frLang from './entries/fr_FR';

const AppLocale = {
  en: enLang,
  zh: zhLang,
  fr: frLang,
};

export default AppLocale;
