import { createSlice } from '@reduxjs/toolkit';

const initialState = [];

const custodiansSlice = createSlice({
  name: 'custodians',
  initialState,
  reducers: {
    setCustodians(state, action) {
      return action.payload;
    },
  },
});

export const { setCustodians } = custodiansSlice.actions;

export default custodiansSlice.reducer;
