import React from 'react';
import { ACCESS } from 'shared/constants/AppEnums.js';
const { ADMIN, STAFF, VIEWER } = ACCESS;

export const hostPagesConfig = [
  {
    auth: [ADMIN, STAFF, VIEWER],
    routes: [
      {
        path: '/hosts',
        exact: true,
        component: React.lazy(() => import('./HostsPage')),
      },
      {
        path: '/hosts/:hostId/:subSection',
        exact: true,
        component: React.lazy(() => import('./HostPage')),
      },
      {
        path: '/hosts/add',
        exact: true,
        component: React.lazy(() => import('./HostInfoPage')),
      },
    ],
  },
];
