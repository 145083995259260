import React from 'react';
import { ACCESS } from 'shared/constants/AppEnums.js';
const { ADMIN } = ACCESS;

const createUserRoute = (auth, basePath, moduleName) => {
  return [
    {
      auth: auth,
      routes: [
        {
          path: `${basePath}`,
          exact: true,
          component: React.lazy(() =>
            import(`./${moduleName}/${moduleName}List.js`),
          ),
        },
      ],
    },
    {
      auth: auth,
      routes: [
        {
          path: `${basePath}/:userId`, // userId can stand for both 'add' and 'uuid'
          exact: true,
          component: React.lazy(() =>
            import(`./${moduleName}/${moduleName}Info.js`),
          ),
        },
      ],
    },
  ];
};

const viewerRoutes = createUserRoute([ADMIN], '/viewers', 'Viewer');

export const userPagesConfig = [...viewerRoutes];
