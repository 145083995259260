export const ThemeStyle = {
  MODERN: 'modern',
  STANDARD: 'standard',
};
export const ThemeStyleRadius = {
  MODERN: 30,
  STANDARD: 4,
};
export const ThemeMode = {
  LIGHT: 'light',
  SEMI_DARK: 'semi-dark',
  DARK: 'dark',
};
export const LayoutType = {
  FULL_WIDTH: 'full-width',
  BOXED: 'boxed',
};
export const NavStyle = {
  DEFAULT: 'default',
  MINI: 'mini',
  STANDARD: 'standard',
  HEADER_USER: 'user-header',
  HEADER_USER_MINI: 'user-mini-header',
  DRAWER: 'drawer',
  BIT_BUCKET: 'bit-bucket',
  H_DEFAULT: 'h-default',
  HOR_LIGHT_NAV: 'hor-light-nav',
  HOR_DARK_LAYOUT: 'hor-dark-layout',
};
export const FooterType = {
  FIXED: 'fixed',
  FLUID: 'fluid',
};
export const HeaderType = {
  DARK: 'dark',
  LIGHT: 'light',
};
export const RouteTransition = {
  NONE: 'none',
  FADE: 'fade',
  SLIDE_LEFT: 'slideLeft',
  SLIDE_RIGHT: 'slideRight',
  SLIDE_UP: 'slideUp',
  SLIDE_DOWN: 'slideDown',
};
export const Fonts = {
  LIGHT: 'Gilroy-Light',
  REGULAR: 'Gilroy-Regular',
  MEDIUM: 'Gilroy-Medium',
  BOLD: 'Gilroy-Bold',
  EXTRA_BOLD: 'Gilroy-ExtraBold',
};

export const ACCESS = {
  ADMIN: 0,
  STAFF: 1,
  VIEWER: 2,
};

export const ALL_ACCESS = [ACCESS.ADMIN, ACCESS.STAFF, ACCESS.VIEWER];

// hardcode it to match the sequence in metadata.sql
export const FILE_TYPE_IDS = {
  HOST_REQUEST_TEMPLATE: 1,
  HOMESTAY_REQUEST_TEMPLATE: 2,
  CRIMINAL_CHECK: 3,
  ANNUAL_DECLARATION: 4,
  ADDITIONAL: 5,
};
