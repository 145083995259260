import React from 'react';

import { ACCESS } from 'shared/constants/AppEnums.js';
const { ADMIN, STAFF, VIEWER } = ACCESS;

export const studentsPagesConfig = [
  {
    auth: [ADMIN, STAFF, VIEWER],
    routes: [
      {
        path: '/students',
        exact: true,
        component: React.lazy(() => import('./StudentsPage')),
      },
      {
        path: '/students/:studentId/:subSection',
        exact: true,
        component: React.lazy(() => import('./StudentPage')),
      },
      {
        path: '/students/add',
        exact: true,
        component: React.lazy(() => import('./studentProfile')),
      },
    ],
  },
];
